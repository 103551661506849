<template>
  <div class="inteExchangeRecordDetailDialog">
    <el-dialog
      :title="title"
      :visible.sync="showInteExchRecordDetailDialog"
      width="45%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <!--  -->
      <div>
        <el-form
          ref="inteExchRecordForm"
          :model="formData"
          :rules="rulesForm"
          label-width="85px"
          size="mini"
        >
          <template v-if="type == 'add'">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="卡名称">
                  <el-input
                    v-model="formData.CardName"
                    placeholder="请输入卡名称"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="卡属性">
                  <el-select
                    class="df-select"
                    v-model="formData.NatureNo"
                    placeholder="请选择卡属性"
                  >
                    <el-option
                      v-for="item in natureSelector"
                      :key="item.NatureNo"
                      :label="item.NatureName"
                      :value="item.NatureNo"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </template>

          <template v-else>
            <el-row :gutter="20">
              <el-col :span="8"> 单号: {{ formData.BillNo }} </el-col>
              <el-col :span="8"> 会员名称: {{ formData.MemberName }} </el-col>
              <el-col :span="8"> 会员电话: {{ formData.MemberPhone }} </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8"> 会员卡号: {{ formData.VipNo }} </el-col>
              <el-col :span="8"> 卡类型: {{ formData.CardName }} </el-col>
              <el-col :span="8"> 卡属性: {{ formData.NatureName }} </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8"> 物品编号: {{ formData.GoodsNo }} </el-col>
              <el-col :span="8"> 物品名称: {{ formData.GoodsName }} </el-col>
              <el-col :span="8"> 物品单位: {{ formData.UnitsName }} </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8"> 兑换数量: {{ formData.ExchCount }} </el-col>
              <el-col :span="8">
                兑换总金额: {{ formData.AmountMoney }}
              </el-col>
              <el-col :span="8"> 兑换总积分: {{ formData.AmountInte }} </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                记账日期: {{ dateFormat("YYYY-mm-dd", formData.SoftwareDate) }}
              </el-col>
              <el-col :span="8">
                兑换时间:
                {{ dateFormat("YYYY-mm-dd HH:MM:SS", formData.AddTime) }}
              </el-col>
              <el-col :span="8">
                核销时间:
                {{
                  formData.VerifyTime
                    ? dateFormat("YYYY-mm-dd HH:MM:SS", formData.VerifyTime)
                    : "未核销"
                }}
              </el-col>
            </el-row>
          </template>
        </el-form>

        <!-- <div v-else>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="单号" prop="BillNo"> </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="会员名称" prop="MemberName"> </el-form-item>
            </el-col>
          </el-row>
        </div> -->
      </div>
      <!--  -->
      <div slot="footer" v-if="type != 'detail'">
        <el-button size="mini" @click="onCloseDialog"> 取 消 </el-button>
        <el-button
          ref="submitButton"
          size="mini"
          type="primary"
          @click="onSubmitData"
        >
          确 定
        </el-button>
      </div>
      <!--  -->
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "",
      type: "detail",
      showInteExchRecordDetailDialog: false,
      formData: {},
      rulesForm: {},
    };
  },

  computed: {
    dateFormat() {
      return function (fmt, timestamp) {
        return this.util.dateFormat(fmt, new Date(timestamp * 1000));
      };
    },
  },

  methods: {
    onShowDialog(type, formData) {
      this.type = type;
      this.formData = formData;
      this.title = type == "detail" ? "记录详情" : "添加兑换";
      this.showInteExchRecordDetailDialog = true;
    },

    onCloseDialog() {
      this.showInteExchRecordDetailDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.el-row {
  margin: 10px 0;
}
</style>