import { render, staticRenderFns } from "./inte-exch-record-detail-dialog.vue?vue&type=template&id=1d6fda13&scoped=true&"
import script from "./inte-exch-record-detail-dialog.vue?vue&type=script&lang=js&"
export * from "./inte-exch-record-detail-dialog.vue?vue&type=script&lang=js&"
import style0 from "./inte-exch-record-detail-dialog.vue?vue&type=style&index=0&id=1d6fda13&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d6fda13",
  null
  
)

export default component.exports