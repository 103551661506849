<template>
  <div class="inte-exch-record-add">
    <el-dialog
      title="新增积分兑换记录"
      :visible.sync="showDialog"
      width="40%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <div class="content-box">
        <el-form ref="form" label-width="90px" size="mini">
          <div>
            <el-row>
              <div class="special-row">
                <el-form-item label="会员卡号：">
                  <el-input
                    v-model="memberCardInfo.VipNo"
                    disabled
                    size="mini"
                  ></el-input>

                  <el-button
                    type="primary"
                    size="mini"
                    plain
                    @click="onShowMemberCard"
                    >选择会员</el-button
                  >
                </el-form-item>
              </div>
            </el-row>

            <el-row :gutter="rowSpan">
              <el-col :span="colSpan">
                <el-form-item label="会员姓名：">
                  <el-input
                    v-model="memberCardInfo.MemberName"
                    disabled
                    size="mini"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="colSpan">
                <el-form-item label="会员电话：">
                  <el-input
                    v-model="memberCardInfo.MemberPhone"
                    disabled
                    size="mini"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="rowSpan">
              <el-col :span="colSpan">
                <el-form-item label="卡类型：">
                  <el-input
                    v-model="memberCardInfo.NatureName"
                    disabled
                    size="mini"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="colSpan">
                <el-form-item label="可用积分：">
                  <el-input
                    v-model="memberCardInfo.InteBalance"
                    disabled
                    size="mini"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>

          <div style="margin-top: 20px;">
            <el-row>
              <div class="special-row">
                <el-form-item label="物品编号：">
                  <el-input
                    v-model="goodsInfo.GoodsNo"
                    disabled
                    size="mini"
                  ></el-input>

                  <el-button
                    type="primary"
                    size="mini"
                    plain
                    @click="onShowExchangeGoods"
                    >选择物品</el-button
                  >
                </el-form-item>
              </div>
            </el-row>

            <el-row :gutter="rowSpan">
              <el-col :span="colSpan">
                <el-form-item label="物品名称：">
                  <el-input
                    v-model="goodsInfo.GoodsName"
                    disabled
                    size="mini"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="colSpan">
                <el-form-item label="物品单价：">
                  <el-input
                    v-model="goodsInfo.GoodsPrice"
                    disabled
                    size="mini"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="colSpan">
                <el-form-item label="物品单位：">
                  <el-input
                    v-model="goodsInfo.UnitsName"
                    disabled
                    size="mini"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="rowSpan">
              <el-col :span="colSpan">
                <el-form-item label="单耗积分：">
                  <el-input
                    v-model="goodsInfo.ExchInte"
                    disabled
                    size="mini"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="colSpan">
                <el-form-item label="兑换数量：">
                  <el-input
                    v-model="submitData.ExchCount"
                    size="mini"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="colSpan">
                <el-form-item label="总耗积分：">
                  <el-input
                    :value="goodsInfo.ExchInte ? (submitData.ExchCount * goodsInfo.ExchInte) : 0"
                    disabled
                    size="mini"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>

          <div>
            <el-form-item label="兑换备注:" size="normal">
              <el-input
                type="textarea"
                :rows="3"
                v-model="submitData.ExchNote"
                :maxlength="300"
                clearable
              >
              </el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>

      <!-- start 脚部 -->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="onCloseDialog">取消</el-button>
        <el-button type="primary" size="small" @click="addExchangeRecord">确定</el-button>
      </span>
      <!-- end 脚部 -->
    </el-dialog>

    <DfMemberCardDialog
      ref="dfMemberCardDialog"
      @select="onSelectMemberCard"
    ></DfMemberCardDialog>

    <DfExchangeGoodsDialog
      ref="dfExchangeGoodsDialog"
      @select="onSelectExchangeGoods"
    ></DfExchangeGoodsDialog>
  </div>
</template>

<script>
import Integral from '@/api/integral.js';

export default {
  data() {
    return {
      rowSpan: 24,
      colSpan: 8,
      submitData: {
        MemberGuid: "",
        VipGuid: "",
        GoodsGuid: "",
        ExchCount: 0,
        ExchNote: "",
      },
      memberCardInfo: {},
      goodsInfo: {},
      showDialog: false,
      submitDisabled: false
    };
  },

  methods: {
    // 兑换物品
    async addExchangeRecord(){
        if(!this.submitData.VipGuid){
            return this.$message.info('请选择会员卡')
        }else if(!this.submitData.GoodsGuid){
            return this.$message.info('请选择兑换物品')
        }

        this.submitDisabled = true
        try {
            let { errcode } = await Integral.addExchangeRecord(this.submitData)
            if(errcode == 0){
                this.$message.success('添加成功')
                this.onCloseDialog()
                this.$emit('success');
            }
        } catch (err) {
            console.log();
        } finally{
            this.submitDisabled = false
        }
    },

    // 打开选择通用会员卡对话框
    onShowMemberCard() {
      this.$refs.dfMemberCardDialog.onShowDialog();
    },

    // 打开选择兑换物品对话框
    onShowExchangeGoods() {
      this.$refs.dfExchangeGoodsDialog.onShowDialog(this.memberCardInfo);
    },

    // 会员卡选中回调事件
    onSelectMemberCard(event) {
      this.memberCardInfo = JSON.parse(JSON.stringify(event));
      this.submitData.MemberGuid = event.MemberGuid;
      this.submitData.VipGuid = event.VipGuid;
    },

    // 兑换物品选中回调时间
    onSelectExchangeGoods(event) {
      this.goodsInfo = JSON.parse(JSON.stringify(event));
      this.submitData.GoodsGuid = event.GoodsGuid;
    },

    // 重置页面数据
    resetPageData() {
      this.submitData = {
        MemberGuid: "",
        VipGuid: "",
        GoodsGuid: "",
        ExchCount: 0,
        ExchNote: "",
      };
      this.memberCardInfo = {}
      this.goodsInfo = {}
    },

    // 打开对话框
    onShowDialog() {
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.resetPageData()
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.inte-exch-record-add {
  .content-box {
    ::v-deep .special-row {
      .el-input {
        width: 21%;
      }

      .el-button {
        margin-left: 10px;
      }
    }

    ::v-deep .is-disabled input {
      color: #606266;
    }
  }
}
</style>
